import { Container, Image } from '@chakra-ui/react'
import swoosh from '../assets/swoosh.svg'

const Swoosh = () => {
  const top = {
    width: '60%',
    maxWidth: '750px',
    position: 'absolute',
    left: '-50',
    transform: 'rotate(150deg)'
  }

  const bottom = {
    width: '60%',
    maxWidth: '750px',
    position: 'absolute',
    right: '-50',
    bottom: '0',
    transform: 'rotate(330deg)'
  }
  return (
    
    <Container>
      <Image src={swoosh} alt='swoosh' sx={top} />
      <Image src={swoosh} alt='swoosh' sx={bottom} />
    </Container>
  )
}

export default Swoosh
