import {
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack
} from '@chakra-ui/react'
import email from '../assets/email-icon.svg'
import phone from '../assets/phone-icon.svg'
import car from '../assets/car.png'
const Footer = () => {
  const style = {
    width: '500px',
    position: 'absolute',
    height: 'auto',
    bottom: '6',
    left: '-325px'
  }
  return (
    <Flex height='285px' marginLeft='200px' marginBottom='6'>
      <VStack alignSelf='flex-end' marginBottom='32px'>
        <Container borderLeftWidth='2px' borderColor='white' padding='3'>
          <Heading textTransform='uppercase' fontSize='xl' margin='1'>
            Robert "Bo" Hoxha
          </Heading>
          <Text textTransform='uppercase' margin='1'>
            Owner/Head Mechanic
          </Text>
          <Flex alignItems='center'>
            <Image src={phone} height='25px' margin='1' />
            <Link href='tel:3462601214'>(346) 260-1214</Link>
          </Flex>
          <Flex alignItems='center'>
            <Image src={email} height='25px' margin='1' />
            <Link href='mailto:Bo@BoProMechanic.com'>Bo@BoProMechanic.com</Link>
          </Flex>
        </Container>
        <Text alignSelf='flex-start' fontSize='xs'>
          &copy; 2023 Burim Hoxha
        </Text>
      </VStack>
      <Image src={car} sx={style} />
    </Flex>
  )
}

export default Footer
