import { Center, Heading, Image, VStack } from '@chakra-ui/react'
import logo from '../assets/car-logo.svg'

const ComingSoon = () => {
  return (
    <Center height="100%">
      <VStack>
        <Image src={logo} alt='car icon' width='50%' />
        <Heading>{'Coming Soon.'.toUpperCase()}</Heading>
      </VStack>
    </Center>
  )
}

export default ComingSoon
