import { ChakraProvider, Flex } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'

import Footer from './Library/Footer'
import Swoosh from './Library/Swoosh'
import ComingSoon from './Library/ComingSoon'

const App = () => {
  const theme = extendTheme({
    fonts: {
      heading: `'Radley', sans-serif`,
      body: `'Montserrat', sans-serif`
    },
    colors: {
      brand: {
        black: '#000000',
        eerieBlack: '#1A1A1B',
        jet: '#353437',
        darkLiver: '#4F4E52',
        dimGray: '#69686D'
      }
    }
  })

  return (
    <ChakraProvider theme={theme}>
      <Flex
        position='relative'
        overflow='hidden'
        direction='column'
        width='100vw'
        height='100vh'
        backgroundColor={theme.colors.brand.black}
        color='white'
      >
        <Swoosh />
        <ComingSoon />
        <Footer />
      </Flex>
    </ChakraProvider>
  )
}

export default App
